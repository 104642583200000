class CsvToJsonService {

    csvToJson = csvString => {

        let jsn = [];
        let fieldName;
        let nChannels = null;
        let tmtOptions = null;
        let sampleReplicates = [];

        const rows = csvString.split('\n');

        rows.forEach((row, j) => {

            if (j > 0) {

                const ary = row.split(',');

                if (j === 1) {
                    nChannels = Number(ary[0]);
                } else if (j === 2 || j === 4) {

                } else if (j === 3) {
                    tmtOptions = {
                        calibrationChannelSelection: { name: 'calibrationChannelSelection', selection: Number(ary[0]) },
                        balanceConditionsSelection: { name: 'balanceConditionsSelection', selection: Number(ary[1]) },
                        balanceReplicatesSelection: { name: 'balanceReplicatesSelection', selection: Number(ary[2]) },
                        triggerChannelSelection: { name: 'triggerChannelSelection', selection: Number(ary[3]) },
                        randomizeSamplesSelection: { name: 'randomizeSamplesSelection', selection: Number(ary[4]) },
                        variabilityAssessmentChanelSelection: { name: 'variabilityAssessmentChanelSelection', selection: Number(ary[5].split('\r')[0]) },
                        autopadSelection: { name: 'autopadSelection', selection: 0 }
                    };

                } else {

                    let obj = {};

                    ary.forEach((e, i) => {

                        if (e.includes('\r')) {
                            e = e.split('\r')[0];
                        }

                        fieldName = this._getFieldName(i);
                        if (['sample', 'replicateGroup'].includes(fieldName)) {
                            obj = {...obj, [fieldName]: Number(e)};
                        } else {
                            obj = {...obj, [fieldName]: e};
                        }

                    });

                    jsn.push(obj);
                }
            }

        });

        let tmp = [];
        jsn.forEach(obj => {
            tmp.push({ ...obj, sampleName: this._getSampleName(obj) });
        });
        jsn = tmp;

        sampleReplicates = this._getSampleReplicates(jsn);

        if (tmtOptions.calibrationChannelSelection.selection && tmtOptions.triggerChannelSelection.selection) {
            return { error: true, message: 'Calibration Channel and Trigger Channel cannot have the same value' };
        }
        return { nChannels, tmtOptions, sampleReplicates, data: jsn, error: false};
    };

    _getFieldName = i => {
        switch (i) {
            case 0:
                return 'sample';

            case 1:
                return 'replicateGroup';

            default:
                let conditionNum = i-1;
                conditionNum < 10 ? conditionNum = 'condition0' + conditionNum : conditionNum = 'condition' + conditionNum;
                return conditionNum;
        }
    };

    _getSampleReplicates = data => {
        let samples = [];

        data.forEach(obj => {
            samples.push(Number(obj.sample));
        });

        samples = [...new Set(samples)].sort();

        const replicates = new Array(samples.length).fill(0);
        samples.forEach(sample => {
            data.forEach(obj => {
                if (sample === Number(obj.sample)) {
                    replicates[sample-1]++;
                }
            });
        });

        return replicates;
    };

    _getSampleName = obj => {
        const keys = Object.keys(obj);
        const conditions = [];

        keys.forEach(key => {
            if (key.includes('condition')) {
                conditions.push(key);
            }
        });

        let sampleName = 'sample_' + obj.sample + '_replicate_' + obj.replicateGroup;
        conditions.forEach((condition, i) => {

            (i+1) < 10 ? condition = 'condition0' + (i+1) : condition = 'condition' + (i+1);
            if (obj[condition] !== '' && obj[condition] !== null && obj[condition] !== undefined) {
                sampleName += '_C' + (i+1) + '_' + obj[condition];
            }
        });

        return sampleName;
    };
}

export default new CsvToJsonService();