import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import CsvToJsonService from '../services/CsvToJsonService';
import StateService from '../services/StateService';
import svgClipboard from '../resources/svg/clipboard-list-solid.svg';

export default () => {

    const [validUpload, setValidUpload] = useState({error: false, message: null});
    const [redirect, setRedirect] = useState(false);

    const dropboxRef = React.createRef();

    const onDrop = e => {
        e.preventDefault();

        const reader = new FileReader();
        reader.onload = e2 => {
            const parsedData = CsvToJsonService.csvToJson(e2.target.result);

            if (parsedData.error) {
                dropboxRef.current.style.borderColor = '#ff003a';
                setValidUpload({ error: parsedData.error, message: parsedData.message });
            } else {

                StateService.nChannels$().next({nChannels: String(parsedData.nChannels)});
                StateService.sampleReplicates$().next({sampleReplicates: parsedData.sampleReplicates});
                StateService.tmtOptions$().next({tmtOptionSelections: parsedData.tmtOptions});
                StateService.tmtGridData$().next({rowData: parsedData.data});

                setRedirect(true);
            }
        };

        if (e.dataTransfer.files) {

            for (const file of e.dataTransfer.files) {
                if (file.type === 'text/csv') {
                    reader.readAsText(file);
                    dropboxRef.current.style.borderColor = '#00ffb2';
                } else {
                    dropboxRef.current.style.borderColor = '#ff003a';
                    setValidUpload({ error: true, message: 'Invalid file format. Please upload as CSV' });
                }
            }
        }

    };

    const onDragOver = e => {
        e.preventDefault();
        dropboxRef.current.style.borderColor = '#00bbff';
        setValidUpload({ error: false, message: null });
    };

    const onDragLeave = e => {
        dropboxRef.current.style.borderColor = '#a7a7a7';
    };

    const onDownload = e => {
        const template = 'channels\n'
            + 'Integer\n'
            + 'calibrationChannel,balanceConditions,balanceReplicates,triggerChannel,randomizeSamples,variabilityAssessmentChannel\n'
            + 'Integer,Integer,Integer,Integer,Integer,Integer\n'
            + 'sample,replicateGroup,condition01,condition02,condition03,condition04\n'
            + 'Integer,Integer,String,String,String,String\n';

        let a = document.createElement('a');
        let file = new Blob([template], { type: 'text/csv' });
        a.href = URL.createObjectURL(file);
        a.download = 'TMT_experiment_template.csv';
        a.click();
    };

    return (
        <React.Fragment>
            <div className="row justify-content-center">
                <div className="col-6 dropbox" ref={dropboxRef} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                    <div style={{textAlign: 'center'}}>
                        Drag and Drop to Upload Experimental Design
                    </div>
                    {validUpload.error &&
                        <div className="errorMessage" style={{textAlign: 'center'}}>
                            {validUpload.message}
                        </div>
                    }
                </div>
            </div>
            <p/>
            <div className="row justify-content-center">
                <div className="col-1" style={{marginLeft: '-7em', zIndex: '5'}}>
                    <img className="staticInfoButton" src={svgClipboard} alt="download_template.svg" onClick={onDownload}/>
                </div>
                <div className="col-5" style={{marginLeft: '-5em'}}>
                    Download Template CSV
                </div>
            </div>
            <p style={{marginBottom: '4em'}}/>
            <h4 style={{textAlign: 'center'}}>Example CSV Experiment Template</h4>
            <div className="row justify-content-center">
                <div className="col-12">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td>channels</td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>calibrationChannel</td>
                                <td>balanceConditions</td>
                                <td>balanceReplicates</td>
                                <td>triggerChannel</td>
                                <td>randomizeSamples</td>
                                <td>variabilityAssessmentChannel</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>1</td>
                                <td>1</td>
                                <td>0</td>
                                <td>1</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>sample</td>
                                <td>replicateGroup</td>
                                <td>condition01</td>
                                <td>condition02</td>
                                <td>condition03</td>
                                <td>condition04</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>1</td>
                                <td>first</td>
                                <td>second</td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>2</td>
                                <td>first</td>
                                <td>second</td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>3</td>
                                <td>first</td>
                                <td>second</td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>4</td>
                                <td>first</td>
                                <td>second</td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>1</td>
                                <td>first</td>
                                <td>second</td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>2</td>
                                <td>first</td>
                                <td>second</td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>3</td>
                                <td>first</td>
                                <td>second</td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>1</td>
                                <td>first</td>
                                <td>second</td>
                                <td> </td>
                                <td> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {redirect &&
                <Redirect push to="/experiment"/>
            }
        </React.Fragment>
    )
}