import React, {useState} from 'react';
import StateService from '../services/StateService';
import svgQuestion from '../resources/svg/question-circle-solid.svg';

export default () => {

    const [showHelp, setShowHelp] = useState(false);

    const onClick = e => {
        StateService.showCreateWizardPopup$().next(!showHelp);
        setShowHelp(!showHelp);
    };

    return (
        <React.Fragment>
            <img className="infoButton" src={svgQuestion} alt="info" onClick={onClick}/>
        </React.Fragment>
    )
}