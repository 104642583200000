const axios = require('axios');

export default class HttpService {

    constructor() {
        
        this.baseUrl = process.env.REACT_APP_NODE_HOST + ':' + process.env.REACT_APP_NODE_PORT;
        //this.baseOptaplexUrl = process.env.REACT_APP_OPTAPLEX_HOST + ':' + process.env.REACT_APP_OPTAPLEX_PORT;
        this.baseOptaplexUrl = process.env.REACT_APP_OPTAPLEX_HOST;

    }

    get = url => {};

    post = (url, body) => {
        return axios.post(this.baseUrl + url, body);
    };

    postOptaplex = (url, body) => {
        return axios.post(this.baseOptaplexUrl + url, body);
    };

    getOptaplex = (url) => {
        return axios.get(this.baseOptaplexUrl + url);
    };

}
