import React from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import StateService from '../services/StateService';
import SVGPlus from '../resources/svg/plus-square-regular.svg';
import SVGCopy from '../resources/svg/copy-solid.svg';
import SVGRecord from '../resources/svg/circle-solid.svg';
import SVGRecordActive from '../resources/svg/circle-solid-active.svg';
import axios from 'axios';

export default (props) => {

    const refRecord = React.createRef();
    let record = true;

    let tnConditions = props.nConditions;
    let gridApi = null;

    const columnDefs = [
        { headerName: 'Sample', field: 'sample', width: 90, suppressSizeToFit: true },
        { headerName: 'Replicate', field: 'replicateGroup', width: 130, suppressSizeToFit: true },
        { headerName: 'Sample Name', field: 'sampleName', width: 160, suppressSizeToFit: true, resizable: true, editable: true }
    ];

    let fNum = '';
    for (let i = 1; i <= props.nConditions; i++) {
        i < 10 ? fNum = '0' + i : fNum = i;
        columnDefs.push({
            headerName: 'Condition_' + fNum,
            field: 'condition' + fNum,
            width: 250,
            editable: true,
            cellEditor: 'agTextCellEditor',
            resizable: true,
            cellEditorParams: {
                maxLength: '300'
            }
        });
    }

    const rowData = [];
    for (let i = 1; i <= props.nSamples; i++) {
        for (let j = 1; j <= props.sampleReplicates[i-1]; j++) {
            let obj = { sample: i, replicateGroup: j, sampleName: 'sample_' + i + '_replicate_' + j };
            for (let k = 1; k <= props.nConditions; k++) {
                k < 10 ? fNum = '0' + k : fNum = k;
                obj = { ...obj, ['condition' + fNum]: '' };
            }
            rowData.push(obj);
        }
    }

    const onGridReady = params => {
        gridApi = params.api;

        if (tnConditions < 5) {
            params.api.sizeColumnsToFit();
        }

        StateService.tmtGridData$().next({ rowData });
        document.getElementById('gridView').scrollIntoView({ behavior: 'smooth' });

    };

    const onCellEditingStopped = params => {

        if (params.colDef.field === 'sampleName') {
            params.data.sampleName = params.value;
        } else {
            const headers = Object.keys(params.data);
            params.data.sampleName = _getSampleName(headers, params);
        }

        const rowNode = gridApi.getRowNode(params.node.id);
        rowNode.setData(params.data);

        StateService.tmtGridData$().next({ rowData });
        _autoFillCells(params);

    };

    const onAddCondition = () => {

        let fNum = '';
        tnConditions++;

        if (tnConditions > props.nConditions) {
            tnConditions < 10 ? fNum = '0' + tnConditions : fNum = tnConditions;
            columnDefs.push({
                headerName: 'Condition_' + fNum,
                field: 'condition' + fNum,
                width: 250,
                editable: true,
                cellEditor: 'agTextCellEditor',
                resizable: true,
                cellEditorParams: {
                    maxLength: '300'
                }
            });

            gridApi.forEachNode((r, i) => {
                tnConditions < 10 ? fNum = '0' + tnConditions : fNum = tnConditions;
                rowData[i] = { ...r.data, ['condition' + fNum]: '' };
            });

            gridApi.setColumnDefs(columnDefs);
            gridApi.setRowData(rowData);

            if (tnConditions < 5) {
                gridApi.sizeColumnsToFit();
            }

        }

    };

    const onCopyConditions = () => {

        let firstRow = null;

        gridApi.forEachNode((row, i) => {

            if (i === 0) {
                firstRow = row;
            }

            const regex = new RegExp('^condition[0-9]*$');
            const headers = Object.keys(row.data);

            for (const header of headers) {
                if (regex.test(header)) {
                    row.data[header] = firstRow.data[header];
                }
            }

            row.data.sampleName = _getSampleName(headers, row);

            const rowNode = gridApi.getRowNode(row.id);
            rowNode.setData(row.data);

        });

    };

    const _autoFillCells = params => {
        if (record) {
            const keys = Object.keys(params.data);
            const conditions = [];

            keys.forEach(key => {
                if (key.includes('condition')) {
                    conditions.push(key);
                }
            });

            gridApi.forEachNode((row, i) => {

                if (params.data.sample === row.data.sample) {

                    let rowObj = {
                        sample: row.data.sample,
                        replicateGroup: row.data.replicateGroup
                    };

                    conditions.forEach(condition => {
                        rowObj = {...rowObj, [condition]: params.data[condition]};
                    });

                    rowObj = {...rowObj, sampleName: _getSampleName(keys, {data: rowObj})};
                    const rowNode = gridApi.getRowNode(row.id);
                    rowNode.setData(rowObj);
                }
            });
        }
    };

    const _getSampleName = (headers, row) => {

        const regex = new RegExp('^condition[0-9]*$');
        const regexSpace = new RegExp(/ /gi);

        let i = 1;
        let newSampleName = 'sample_' + row.data.sample + '_replicate_' + row.data.replicateGroup;
        for (const header of headers) {
            if (regex.test(header)) {
                if (row.data[header]) {
                    newSampleName += '_C' + i + '_' + row.data[header].replace(regexSpace, '_');
                }
                i++;
            }
        }

        return newSampleName;

    };

    const onSave = () => {
        axios.post('http://localhost:3001/save', { data: rowData });
    };

    const onRecord = () => {

        record = !record;

        if (record) {
            refRecord.current.src = SVGRecordActive;
        } else {
            refRecord.current.src = SVGRecord;
        }

    };

    return (
        <React.Fragment>
            <div className="row justify-content-end" id="gridView">
                <div className="col-1 text-right">
                    <img className="newColumnButton" src={SVGRecordActive} alt="record" onClick={onRecord} ref={refRecord} style={{marginRight: '0.25em'}} title="Record conditions"/>
                    <img className="newColumnButton" src={SVGCopy} alt="copy-conditions" onClick={onCopyConditions} style={{marginRight: '0.25em'}} title="Duplicate conditions"/>
                    <img className="newColumnButton" src={SVGPlus} alt="new-column" onClick={onAddCondition} title="Add condition"/>
                </div>
            </div>
            <div className="ag-theme-balham-dark">
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    domLayout="autoHeight"
                    onCellEditingStopped={onCellEditingStopped}
                    onGridReady={onGridReady}>
                </AgGridReact>
                <p/>
            </div>
            <div onClick={onSave}>SAVE</div>
        </React.Fragment>
    )

}