import React from 'react';
import './App.css';
import {BrowserRouter, Route} from 'react-router-dom';
import Navbar from './layout/navbar';
//import Home from './components/home';
import CreateWizard from './components/createWizard';
import CreateManual from './components/createManual';
import TmtExperimentInterface from './components/tmtExperimentInterface';
import Upload from './components/upload';

function App() {
    return (
      <div>
          <BrowserRouter>
            <Navbar/>
            <p/>
            <div className="container">
                {/* <Route exact path="(/|/home)" component={Home}/> */}
                <Route exact path="(/|/home|/create/experiment/wizard)" component={CreateWizard}/>
                <Route exact path="/upload" component={Upload}/>
                <Route exact path="/create/experiment/manual" component={CreateManual}/>
                <Route exact path="/experiment" component={TmtExperimentInterface}/>
            </div>
          </BrowserRouter>
      </div>
  );
}

export default App;
