import React, {Component} from 'react';
import StateService from '../services/StateService';
import HttpService from '../services/HttpService';
import CsvExportService from '../services/CsvExportService';
import TmtConstraintService from '../services/TmtConstraintService';
import ManufacturerChannelLabelService from '../services/ManufacturerChannelLabelService';
import routes from '../resources/routes.json';
import SVGCsv from '../resources/svg/file-csv-solid.svg';
import SVGJson from '../resources/svg/json-icon.svg';

class TmtExperimentInterface extends Component {

    constructor() {
        super();

        this.http = new HttpService();
        this.csvExportService = new CsvExportService();
        this.constraintService = new TmtConstraintService();
        this.mfrService = new ManufacturerChannelLabelService();

        this.nSamples = 0;
        this.sampleReplicates = null;
        this.nChannels = null;
        this.tmtGridData = null;
        this.tmtOptions = null;

        this.plexDesign = undefined;

        this.state = {
            plexDesign: null,
            isLoading: true
        };

        StateService.sampleReplicates$().subscribe(res => {
            if (res) {
                this.sampleReplicates = res.sampleReplicates;
                this.nSamples = StateService.nSamples;
            }
        });
        StateService.nChannels$().subscribe(res => {
            if (res) {
                this.nChannels = res.nChannels;
            }
        });
        StateService.tmtGridData$().subscribe(res => {
            if (res) {
                this.tmtGridData = res.rowData;
            }
        });
        StateService.tmtOptions$().subscribe(res => {
            if (res) {
                this.tmtOptions = res.tmtOptionSelections;
            }
        });

    }

    componentDidMount() {
        this.createExerimentsByPlex();
    }

    componentDidUpdate() {
        this.createEventListeners();
    }

    createExerimentsByPlex = () => {

        this.http.post(routes.server.getExperimentName, { })
            .then(res => {

                this.placeSamplesInPlexWithOptaplex(res.data.experimentId);

            });

    };

    placeSamplesInPlexWithOptaplex = experimentId => {

        const CALIBRATION = 'calibration';
        const TRIGGER = 'trigger';

        let formattedGridData = [];
        let calibrationOrTrigger;
        let bCalibrationOrTriggerSelected = false;

        if (this.tmtOptions.calibrationChannelSelection.selection) {
            calibrationOrTrigger = CALIBRATION;
            bCalibrationOrTriggerSelected = true;
        }

        if (this.tmtOptions.triggerChannelSelection.selection) {
            calibrationOrTrigger = TRIGGER;
            bCalibrationOrTriggerSelected = true;
        }

        this.tmtGridData.forEach(obj => {

            let gridDataObj = {};
            const conditions = [];
            const keys = Object.keys(obj);

            keys.forEach(key => {
                if (key.includes('condition')) {
                    conditions.push(obj[key]);
                } else {
                    gridDataObj = { ...gridDataObj, [key]: obj[key] };
                }
            });

            formattedGridData.push({ ...gridDataObj, conditions });

        });

        const nPlexesRequired = this._getNumberOfPlexes(this.nChannels, this.tmtGridData.length);

        const options = {
            "numberOfPlexes": nPlexesRequired,
            "numberOfChannels": this.nChannels,
            "calibrationChannelSelection": this.tmtOptions.calibrationChannelSelection.selection,
            "balanceConditionsSelection": this.tmtOptions.balanceConditionsSelection.selection,
            "balanceReplicatesSelection": this.tmtOptions.balanceReplicatesSelection.selection,
            "triggerChannelSelection": this.tmtOptions.triggerChannelSelection.selection,
            "randomizeSamplesSelection": this.tmtOptions.randomizeSamplesSelection.selection,
            "variabilityAssessmentChanelSelection": this.tmtOptions.variabilityAssessmentChanelSelection.selection,
            "autopadSelection": this.tmtOptions.autopadSelection.selection
        };

        formattedGridData = this.constraintService.addControlChannels(formattedGridData, this.nChannels, calibrationOrTrigger, bCalibrationOrTriggerSelected, this.tmtOptions.variabilityAssessmentChanelSelection.selection);
        formattedGridData = this.constraintService.randomizeData(formattedGridData, this.tmtOptions.randomizeSamplesSelection.selection);

        options.numberOfPlexes = this._getNumberOfPlexes(this.nChannels, formattedGridData.length);
        const optionsAndData = { options, data: formattedGridData };

        this.http.postOptaplex(routes.optaplex.solve, optionsAndData)
            .then(res => {
                let num;
                let plexDesign = [];
                let plexArray = [];

                for (let i = 0; i < this._getNumberOfPlexes(this.nChannels, res.data.resultList.length); i++) {
                    plexArray[i] = [];
                }

                const labels = [];

                // create channel labels
                const mfrChannelLabels = this.mfrService.getMFRChannelLabelsArray(this.nChannels);
                for (let i = 1; i <= this.nChannels; i++) {
                    i < 10 ? num = '0' + i : num = i;
                    labels.push(mfrChannelLabels[i-1] + '/' + num);
                }

                res.data.resultList.forEach(obj => {
                    plexArray[obj.plexNum - 1].push(obj);
                });

                plexArray.forEach((obj, i) => {

                    const sortedSamples = this._sortSampleByPlexAndChannel(obj);
                    const sampleAssignments = [];

                    sortedSamples.forEach(sample => {
                        sampleAssignments.push(sample.name);
                    });

                    plexDesign.push({
                        plex: i + 1,
                        experimentId,
                        design: this._getDesignName(this.nChannels),
                        channels: this.nChannels,
                        labels,
                        sampleAssignments
                    });
                });

                this.plexDesign = plexDesign;
                this.setState({ plexDesign, isLoading: false });

            })
            .catch(err => {
                console.log(err.response);
            });

    };

    _getNumberOfPlexes = (nChannels, nSamples) => {
        return Math.ceil(nSamples / nChannels);
    };

    _sortSampleByPlexAndChannel = samples => {

        samples.sort((s1, s2) => {
            if (s1.plexNum > s2.plexNum) {
                return 1;
            } else if (s1.plexNum < s2.plexNum) {
                return -1;
            } else {
                return 0;
            }
        });

        samples.sort((s1, s2) => {
            if (s1.channelNum > s2.channelNum) {
                return 1;
            } else if (s1.channelNum < s2.channelNum) {
                return -1;
            } else {
                return 0;
            }
        });

        return samples;
    };

    _getDesignName = nChannels => {
        switch (Number(nChannels)) {
            case 4:
                return 'TMT_Four_Plex';

            case 6:
                return 'TMT_Six_Plex';

            case 10:
                return 'TMT_Ten_Plex';

            case 11:
                return 'TMT_Eleven_Plex';

            case 16:
                return 'TMT_Sixteen_Plex';

            default:
                return 'TMT_Other_Plex';
        }
    };

    createEventListeners = () => {
        let sample = null;
        let parent = null;
        let newParent = null;

        const onmousedown = e => {
            if (e.target.className !== '' && e.target.id && e.target.parentNode.id) {
                parent = document.getElementById(e.target.parentNode.id);
                sample = document.getElementById(e.target.id);
            }
        };

        const dragover = e => {
            e.preventDefault();
        };

        const drop = e => {

            let unselectedSample = null;

            if (e.target.id.includes('holder')) {
                newParent = e.target;
                unselectedSample = e.target.children[0];
            } else {
                newParent = e.target.parentNode;
                unselectedSample = e.target;
            }

            this._updatePlexDesign(parent.id, newParent.id);

            parent.append(unselectedSample);
            newParent.append(sample)
        };

        window.addEventListener('mousedown', onmousedown);
        const containers = document.getElementsByClassName('holder');
        for (const container of containers) {
            container.addEventListener('dragover', dragover);
            container.addEventListener('drop', drop);
        }

    };

    _updatePlexDesign = (selectedPlexAndSample, unselectedPlexAndSample) => {

        const sampleSplit1 = selectedPlexAndSample.split('-');
        const sampleSplit2 = unselectedPlexAndSample.split('-');

        const sample1 = {
            plex: sampleSplit1[1],
            sample: sampleSplit1[2],
            value: undefined
        };
        const sample2 = {
            plex: sampleSplit2[1],
            sample: sampleSplit2[2],
            value: undefined
        };

        sample1.value = this.state.plexDesign[sample1.plex].sampleAssignments[sample1.sample];
        sample2.value = this.state.plexDesign[sample2.plex].sampleAssignments[sample2.sample];

        const plexDesignCopy = this.state.plexDesign;
        plexDesignCopy[sample1.plex].sampleAssignments[sample1.sample] = sample2.value;
        plexDesignCopy[sample2.plex].sampleAssignments[sample2.sample] = sample1.value;

        this.plexDesign = plexDesignCopy;

    };

    onDownload = (dataType, data) => {

        const date = new Date();
        let a = document.createElement('a');
        let file = null;
        let fileType;
        let month;
        let day;

        date.getMonth() + 1 < 10 ? month = '0' + String(date.getMonth()) : month = String(date.getMonth());
        date.getDate() < 10 ? day = '0' + String(date.getDate()) : day = String(date.getDate());

        const fdate = String(date.getFullYear()) + month + day;

        if (dataType === 'json') {
            file = new Blob([JSON.stringify(data, null, 2)], {type: 'text/plain'});
            fileType = '.json';
        } else {
            file = new Blob([data], {type: 'text/csv'});
            fileType = '.csv'
        }

        a.href = URL.createObjectURL(file);
        a.download = fdate + '_tmt_experiment_' + this.plexDesign[0].experimentId + fileType;
        a.click();

    };

    onCsvDownload = () => {
        const csvString = this.csvExportService.json2csv(this.plexDesign);
        this.onDownload('csv', csvString);

    };

    setCss = (obj, i, i2) => {

        let classname;

        switch (obj.sampleAssignments[i2]) {

            case 'calibration':
                classname = 'col-12 channelSample channelColorCalTrig';
                break;

            case 'trigger':
                classname = 'col-12 channelSample channelColorCalTrig';
                break;

            case 'variability_assessment':
                classname = 'col-12 channelSample channelColorVarAssess';
                break;

            default:
                classname = 'col-12 channelSample';
                break;
        }

        return (
            <div className={classname} id={'sample-'+ i + '-' + i2} draggable="true">{obj.sampleAssignments[i2]} </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                {this.state.isLoading &&
                    <div className="row justify-content-center">
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                }
                {!this.state.isLoading &&
                    <React.Fragment>
                        <div className="row justify-content-end">
                            <div className="col-12 text-right" style={{ marginBottom: '-0.65em'}}>
                                <img className="csvDownloadButton" src={SVGCsv} alt="download" onClick={this.onCsvDownload}/>
                                <img className="jsonDownloadButton" src={SVGJson} alt="json-download" onClick={()=>{this.onDownload('json', this.plexDesign)}}/>
                            </div>
                        </div>
                        <div className="">
                            { this.state.plexDesign && this.state.plexDesign.map((obj, i) => {
                                let formattedPlexNumber = null;
                                i+1 < 10 ? formattedPlexNumber = '00' + (i+1) :
                                    i+1 < 100 && i+1 >= 10 ? formattedPlexNumber = '0' + (i+1) : formattedPlexNumber = i+1;
                                return (
                                    <React.Fragment key={i}>
                                        <div className="container plexGroup">
                                            <div className="row">
                                                <div className="col-6">
                                                    <h5 className="plexTitle">{'TMT-' + obj.experimentId + '-'+formattedPlexNumber}</h5>
                                                </div>
                                            </div>
                                            { obj.labels.map((label, i2) => {
                                                return (
                                                    <React.Fragment key={i2}>
                                                        <div className="row justify-content-center channelRow col-11">
                                                            <div className="channelLabel col-2">{label}</div>
                                                            <div className="holder col-9" id={'holder-' + i + '-' + i2}>
                                                                {this.setCss(obj, i, i2)}
                                                            </div>
                                                        </div>
                                                        <p/>
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </div>
                                        <p/>
                                    </React.Fragment>
                                )
                                })
                            }
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default TmtExperimentInterface;