export default class ManufacturerChannelLabelService {

    getMFRChannelLabelsArray = nChannels => {

        switch (Number(nChannels)) {

            case 6:
                return [
                    'TMT6-126',
                    'TMT6-127',
                    'TMT6-128',
                    'TMT6-129',
                    'TMT6-130',
                    'TMT6-131'
                ];

            case 10:
                return [
                    'TMT10–126',
                    'TMT10–127N',
                    'TMT10–127C',
                    'TMT10–128N',
                    'TMT10–128C',
                    'TMT10–129N',
                    'TMT10–129C',
                    'TMT10–130N',
                    'TMT10–130C',
                    'TMT10–131'
                ];

            case 11:
                return [
                    'TMT11–126',
                    'TMT11–127N',
                    'TMT11–127C',
                    'TMT11–128N',
                    'TMT11–128C',
                    'TMT11–129N',
                    'TMT11–129C',
                    'TMT11–130N',
                    'TMT11–130C',
                    'TMT11–131',
                    'TMT11-131C'
                ];

            case 16:
                return [
                    'TMTpro-126',
                    'TMTpro-127N',
                    'TMTpro-127C',
                    'TMTpro-128N',
                    'TMTpro-128C',
                    'TMTpro-129N',
                    'TMTpro-129C',
                    'TMTpro-130N',
                    'TMTpro-130C',
                    'TMTpro-131N',
                    'TMTpro-131C',
                    'TMTpro-132N',
                    'TMTpro-132C',
                    'TMTpro-133N',
                    'TMTpro-133C',
                    'TMTpro-134N'
                ];

            default:
                return [];
        }

    }

}