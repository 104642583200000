export default class TmtConstraintService {

    randomizeData = (data, bSelection) => {

        if (bSelection) {

            const min = 0;
            const max = data.length - 1;
            const randomizedData = [];
            const usedIndexes = [];

            while (randomizedData.length - 1 < max) {

                const e = Math.floor(Math.random() * (max - min + 1) + min);
                if (!usedIndexes.includes(e)) {
                    usedIndexes.push(e);
                    randomizedData.push(data[e]);
                }

            }

            return randomizedData;

        }

        return data;
    };

    addControlChannels = (data, nChannels, calibrationOrTrigger, bCalOrTrigSelection, bVarAssessSelection) => {

        let nPlexes = this._getNumberOfPlexes(nChannels, data.length);
        let nChannelsToAdd = 0;
        let lastNPlex = 0;
        let firstPass = true;

        if (bCalOrTrigSelection) { nChannelsToAdd++ }
        if (bVarAssessSelection) { nChannelsToAdd++ }

        while (true) {
            const totalChannels = nPlexes * nChannelsToAdd + data.length;
            nPlexes = Math.ceil(totalChannels / nChannels);

            if (nPlexes % nChannels === 0 || nPlexes === lastNPlex) {
                if (firstPass) nPlexes++;
                break;
            }

            lastNPlex = nPlexes;
            firstPass = false;
        }

        data = this._addCalibrationOrTriggerChannel(data, nChannels, nPlexes, calibrationOrTrigger, bCalOrTrigSelection);
        data = this._addVariabilityAssessmentChannel(data, nChannels, nPlexes, bVarAssessSelection);

        return data;
    };

    addPadSamples = (data, nChannels, sampleReplicates, bCalOrTrigSelection, bVarAssessSelection) => {

        const vacancyData = this.getVacantChannels(data, nChannels, 'calibration', bCalOrTrigSelection, bVarAssessSelection);
        let prelimFormattedData = vacancyData.prelimFormattedData;

        let e = 0;
        for (let i = 0; i < vacancyData.nVacantChannels; i++) {

            sampleReplicates[e] = Number(sampleReplicates[e])+1;
            prelimFormattedData.push({ sample: e+1, replicateGroup: sampleReplicates[e], sampleName: '', conditions: ['Pad'] });

            if (e === sampleReplicates.length-1) {
                e = 0;
            } else {
                e++;
            }

        }

        return { nSamples: sampleReplicates.length, sampleReplicates };


    };

    getVacantChannels = (data, nChannels, calibrationOrTrigger, bCalOrTrigSelection, bVarAssessSelection) => {
        const prelimFormattedData = this.addControlChannels(data, nChannels, 'calibration', bCalOrTrigSelection, bVarAssessSelection);
        const nRequiredPlexes = this._getNumberOfPlexes(nChannels, prelimFormattedData.length);
        const nVacantChannels = nChannels * nRequiredPlexes - prelimFormattedData.length;

        return { prelimFormattedData, nVacantChannels };
    };

    _addCalibrationOrTriggerChannel = (data, nChannels, nPlexes, calibrationOrTrigger, bSelection) => {
        if (bSelection) {

            const CALIBRATION = 'calibration';
            const TRIGGER = 'trigger';

            const conditions = [];
            let sampleName;

            if (calibrationOrTrigger === CALIBRATION) {
                conditions.push(CALIBRATION);
                sampleName = CALIBRATION;
            } else {
                conditions.push(TRIGGER);
                sampleName = TRIGGER;
            }

            for (let i = 0; i < nPlexes; i++) {
                data.push({ sample: this._getMaxSampleNum(data), replicateGroup: i+1, sampleName, conditions });
            }

        }

        return data;
    };

    _addVariabilityAssessmentChannel = (data, nChannels, nPlexes, bSelection) => {

        if (bSelection) {

            const VARIABILITY_ASSESSMENT = 'variability_assessment';
            const conditions = [VARIABILITY_ASSESSMENT];

            for (let i = 0; i < nPlexes; i++) {
                data.unshift({ sample: this._getMaxSampleNum(data), replicateGroup: i+1, sampleName: VARIABILITY_ASSESSMENT, conditions });
            }

            return data;
        }

        return data;

    };

    _getMaxSampleNum = (data) => {
        let maxSampleNum = 0;
        data.forEach(obj => {
            if (obj.sample > maxSampleNum) { maxSampleNum = obj.sample }
        });
        return ++maxSampleNum;
    };

    _getNumberOfPlexes = (nChannels, nSamples) => {
        return Math.ceil(nSamples / nChannels);
    };
}