import React from 'react';

export default () => {
    return (
        <div className="row justify-content-center">
            <div className="tmtOptionsPopup col-6">
                <p/>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <th># Samples</th>
                            <td>Include the number of unique biological samples (not replicates) here. For instance, if you are profiling two different cell lines then enter 2. If you are profiling one cell line under two conditions, then enter 1.</td>
                        </tr>
                        <tr>
                            <th># Conditions</th>
                            <td>Enter the number of different variables that describe the experimental setup. For instance, drug treatment (control/drug treated) is one condition.</td>
                        </tr>
                        <tr>
                            <th>Channels</th>
                            <td>Pick the TMT format you will use (6, 10, 11, 16)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}