import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import GridSampleSummary from './gridSampleSummary';
import PadSampleNotification from './PadSampleNotification';
import TooltipCreateWizardButton from './tooltipCreateWizardButton';
import TooltipCreateWizardPopup from './tooltipCreateWizardPopup';
import StateService from '../services/StateService';
import TmtConstraintService from '../services/TmtConstraintService';
import SVGOptions from '../resources/svg/th-list-solid.svg';
import SVGX from '../resources/svg/times-circle-solid.svg';

class CreateWizard extends Component {

    constructor () {
        super();

        this.constraintService = new TmtConstraintService();

        this.tmtOptionSelectionClasses = {
            yesSelected: 'binaryYesSelected',
            noSelected: 'binaryNoSelected',
            yesNotSelected: 'binaryYesNotSelected',
            noNotSelected: 'binaryNoNotSelected'
        };

        this.htmlTagNames = {
            nSamples: 'nSamples',
            nConditions: 'nConditions',
            channels: 'channels'
        };

        this.nErrorMessage = 'Cannot use non-numerical values';
        this.zeroErrorMessage = 'Cannot assign zero replicates';

        // name field must be use in name and the *field*Selection must be identical
        this.showOptions = false;
        this.tmtOptionSelections = this._initializeOptions();

        StateService.tmtOptions$().next(this.tmtOptionSelections);

        this.state = {
            nSamples: 0,
            nConditions: 0,
            channel: null,
            nSamplesError: false,
            nConditionsError: false,
            onApplyErrorMsg: '',
            createGrid: false,
            askSampleReplicates: false,
            sampleReplicates: [],
            showTooltip: false
        };
    }

    componentDidMount() {
        StateService.showCreateWizardPopup$().subscribe(res => {
            this.setState({ showTooltip: res })
        });
    }

    onKeyUp = e => {

        const regex = new RegExp('^[0-9]*$');
        const regexZero = new RegExp('^[0]*$');
        const regexSampleReplicate = new RegExp('^sampleReplicate[0-9]*$');

        if (!regexSampleReplicate.test(e.target.name) && regex.test(e.target.value)) {

            if (e.target.name === this.htmlTagNames.nSamples) {
                let ary = [];
                for (let i = 0; i < e.target.value; i++) {
                    ary.push(1);
                }

                this.setState({ sampleReplicates: ary });
            }

            this.setState({
                [e.target.name]: e.target.value,
                nSamplesError: false,
                nConditionsError: false
            });

        } else if (regexSampleReplicate.test(e.target.name)) {

            const index = Number(e.target.name.split('sampleReplicate')[1]) - 1;

            if (!regexZero.test(Number(e.target.value))) {
                const tary = this.state.sampleReplicates;
                tary[index] = Number(e.target.value);

                this.setState({sampleReplicates: tary});

                if (!regex.test(e.target.value)) {
                    document.getElementById('sampleReplicateError' + (index + 1)).innerHTML = this.nErrorMessage;
                } else {
                    document.getElementById('sampleReplicateError' + (index + 1)).innerHTML = '';
                    StateService.sampleReplicates$().next({sampleReplicates: tary});
                }

            } else {
                document.getElementById('sampleReplicateError' + (index + 1)).innerHTML = this.zeroErrorMessage;
            }

        } else {

            switch (e.target.name) {

                case this.htmlTagNames.nSamples:
                    this.setState({ nSamplesError: true });
                    break;

                case this.htmlTagNames.nConditions:
                    this.setState({ nConditionsError: true });
                    break;

                default:
                    break;

            }

        }

    };

    onChange = e => {
        StateService.nChannels$().next({ nChannels: e.target.value });
        this.setState({channel: e.target.value});
    };

    onApplySampleSummary = () => {
        let errorMessage = [];

        if (!this.state.nSamples) { errorMessage.push('# Samples') }
        if (!this.state.nConditions) { errorMessage.push('# Conditions') }
        if (!this.state.channel) { errorMessage.push('Channels') }

        this.setState({ onApplyErrorMsg: errorMessage });

        if (!this.state.nSamplesError && !this.state.nConditionsError && errorMessage.length === 0) {
            const elements = document.getElementsByClassName('userInput1');
            for (let i = 0; i < elements.length; i++) { elements[i].disabled = true }
            this.setState({ askSampleReplicates: true });
        } else {
            this.setState({ askSampleReplicates: false });
        }

    };

    onApplySampleReplicates = () => {
        let prelimFormattedData = this._createPreliminaryData();
        let bCalOrTrigSelection = false;
        let padCounts;

        if (this.tmtOptionSelections.calibrationChannelSelection.selection || this.tmtOptionSelections.triggerChannelSelection.selection) {
            bCalOrTrigSelection = true;
        }

        if (this.tmtOptionSelections.autopadSelection.selection) {
            padCounts = this.constraintService.addPadSamples(prelimFormattedData, this.state.channel, this.state.sampleReplicates, bCalOrTrigSelection, this.tmtOptionSelections.variabilityAssessmentChanelSelection.selection);
        } else {
            padCounts = { nSamples: this.state.sampleReplicates.length, sampleReplicates: this.state.sampleReplicates };
        }

        const elements = document.getElementsByClassName('userInput2');
        for (let i = 0; i < elements.length; i++) { elements[i].disabled = true }
        this.setState({ nSamples: padCounts.nSamples, sampleReplicates: padCounts.sampleReplicates, createGrid: true }, () => {
            document.getElementsByClassName('optionsBox')[0].style.display = 'none';
            document.getElementsByClassName('optionsBox')[1].style.display = 'none';
        });

        StateService.tmtOptions$().next({ tmtOptionSelections: this.tmtOptionSelections });
    };

    onReset = () => {

        this.showOptions = false;
        this.tmtOptionSelections.calibrationChannelSelection.selection = 1;
        this.tmtOptionSelections.balanceConditionsSelection.selection = 1;
        this.tmtOptionSelections.balanceReplicatesSelection.selection = 1;
        this.tmtOptionSelections.triggerChannelSelection.selection = 0;
        this.tmtOptionSelections.randomizeSamplesSelection.selection = 1;
        this.tmtOptionSelections.randomizeSamplesSelection.selection = 1;

        document.getElementsByName(this.htmlTagNames.nSamples)[0].value = '';
        document.getElementsByName(this.htmlTagNames.nConditions)[0].value = '';
        document.getElementsByName(this.htmlTagNames.channels)[0].selectedIndex = 0;
        document.getElementsByClassName('optionsBox')[0].style.display = 'block';
        document.getElementsByClassName('optionsBox')[1].style.display = 'block';
        document.getElementById('infoButtonCheck').style.display = 'block';
        document.getElementById('infoButtonX').style.display = 'none';
        document.getElementById('tmtSelectionOptionsGroup').style.display = 'none';
        document.getElementById('padSampleNotification').style.display = 'block';

        this.onOpenSetSelection();
        document.getElementById(this.tmtOptionSelections.triggerChannelSelection.name).children[0].className = this.tmtOptionSelectionClasses.yesNotSelected;
        document.getElementById(this.tmtOptionSelections.triggerChannelSelection.name).children[1].className = this.tmtOptionSelectionClasses.noSelected;

        const elementsUserInput1 = document.getElementsByClassName('userInput1');
        const elementsUserInput2 = document.getElementsByClassName('userInput2');
        for (let i = 0; i < elementsUserInput1.length; i++) { elementsUserInput1[i].disabled = false }
        for (let i = 0; i < elementsUserInput2.length; i++) { elementsUserInput2[i].disabled = false }

        this.setState({
            nSamples: 0,
            nConditions: 0,
            channel: null,
            nSamplesError: false,
            nConditionsError: false,
            onApplyErrorMsg: '',
            createGrid: false,
            askSampleReplicates: false,
            sampleReplicates: []
        });

        StateService.tmtGridData$().next({});
        StateService.tmtOptions$().next(this._initializeOptions());
        StateService.resetCreateWizard$().next({});
    };

    createReplicates = () => {
        let ary = [];
        for (let i = 1; i <= this.state.nSamples; i++) { ary.push(i) }
        return ary.map(i => {
            return (
                <React.Fragment key={i}>
                <div className="row justify-content-center">
                    <div className="col-3">
                        <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Sample {i}</span>
                            </div>
                            <input type="text" className="form-control userInput2" name={"sampleReplicate" + i} onKeyUp={this.onKeyUp}/>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-3 errorMessage" id={"sampleReplicateError" + i}> </div>
                </div>
                <p/>
                </React.Fragment>
            )
        });
    };

    onOptionsClick = e => {
        // must interact with DOM manually to avoid rerendering ag-grid component causing errors

        this.showOptions = !this.showOptions;
        if (!this.showOptions) {
            document.getElementById('infoButtonCheck').style.display = 'block';
            document.getElementById('infoButtonX').style.display = 'none';
            document.getElementById('tmtSelectionOptionsGroup').style.display = 'none';
        } else {
            document.getElementById('infoButtonCheck').style.display = 'none';
            document.getElementById('infoButtonX').style.display = 'block';
            document.getElementById('tmtSelectionOptionsGroup').style.display = 'block';
        }
    };

    onOptionSelect = e => {

        let value = 0;

        if (this.tmtOptionSelections[e.target.parentNode.id].name === 'autopadSelection') {
            if (this.tmtOptionSelections[e.target.parentNode.id].selection) {
                document.getElementById('padSampleNotification').style.display = 'none';
            } else {
                document.getElementById('padSampleNotification').style.display = 'block';
            }
        }

        // if 'yes' is selected then switch to 'no' being selected
        if (this.tmtOptionSelections[e.target.parentNode.id].selection) {
            value = 0;
            e.target.parentNode.children[0].className = this.tmtOptionSelectionClasses.yesNotSelected;
            e.target.parentNode.children[1].className = this.tmtOptionSelectionClasses.noSelected;
        } else { // if 'no' is selected then switch to 'yes' being selected
            value = 1;
            e.target.parentNode.children[0].className = this.tmtOptionSelectionClasses.yesSelected;
            e.target.parentNode.children[1].className = this.tmtOptionSelectionClasses.noNotSelected;
        }

        this.tmtOptionSelections[e.target.parentNode.id].selection = value;
        this._exclusiveCalibrationChannelSelect(e.target.parentNode.id);

        StateService.tmtOptions$().next(this.tmtOptionSelections);

    };

    _createPreliminaryData = () => {
        const prelimFormattedData = [];
        this.state.sampleReplicates.forEach((nSamples, index) => {
            for (let i = 1; i <= nSamples; i++) {
                prelimFormattedData.push({ sample: index+1, replicateGroup: i, sampleName: '', conditions: [] });
            }
        });
        return prelimFormattedData;
    };

    _exclusiveCalibrationChannelSelect = id => {

        let tmtOptionElsIndexes = {};
        let oppositeIdSelection;

        const tmtOptionEls = document.getElementsByClassName('tmtOptionBinary');
        for (let i = 0; i < tmtOptionEls.length; i ++) {
            if (id === 'calibrationChannelSelection') {
                oppositeIdSelection = 'triggerChannelSelection';
            }

            if (id === 'triggerChannelSelection') {
                oppositeIdSelection = 'calibrationChannelSelection';
            }

            tmtOptionElsIndexes = { ...tmtOptionElsIndexes, [tmtOptionEls[i].id]: i };
        }

        for (let i = 0; i < tmtOptionEls.length; i ++) {
            if (tmtOptionEls[i].id === oppositeIdSelection) {
                if (!this.tmtOptionSelections[tmtOptionEls[i].id].selection) {
                    tmtOptionEls[tmtOptionElsIndexes[oppositeIdSelection]].children[0].className = this.tmtOptionSelectionClasses.yesSelected;
                    tmtOptionEls[tmtOptionElsIndexes[oppositeIdSelection]].children[1].className = this.tmtOptionSelectionClasses.noNotSelected;
                    this.tmtOptionSelections[tmtOptionEls[i].id].selection = 1;
                } else {
                    tmtOptionEls[tmtOptionElsIndexes[oppositeIdSelection]].children[0].className = this.tmtOptionSelectionClasses.yesNotSelected;
                    tmtOptionEls[tmtOptionElsIndexes[oppositeIdSelection]].children[1].className = this.tmtOptionSelectionClasses.noSelected;
                    this.tmtOptionSelections[tmtOptionEls[i].id].selection = 0;
                }
            }
        }

    };

    _initializeOptions = () => {
        return {
            calibrationChannelSelection: { name: 'calibrationChannelSelection', selection: 1 },
            balanceConditionsSelection: { name: 'balanceConditionsSelection', selection: 1 },
            balanceReplicatesSelection: { name: 'balanceReplicatesSelection', selection: 1 },
            triggerChannelSelection: { name: 'triggerChannelSelection', selection: 0 },
            randomizeSamplesSelection: { name: 'randomizeSamplesSelection', selection: 1 },
            variabilityAssessmentChanelSelection: { name: 'variabilityAssessmentChanelSelection', selection: 1 },
            autopadSelection: { name: 'autopadSelection', selection: 1 }
        };
    };

    onOpenSetSelection = (e) => {

        const tmtOptionEls = document.getElementsByClassName('tmtOptionBinary');
        for (let i = 0; i < tmtOptionEls.length; i ++) {

            if (this.tmtOptionSelections[tmtOptionEls[i].id]) {
                tmtOptionEls[i].children[0].className = this.tmtOptionSelectionClasses.yesSelected;
                tmtOptionEls[i].children[1].className = this.tmtOptionSelectionClasses.noNotSelected;
            } else {
                tmtOptionEls[i].children[0].className = this.tmtOptionSelectionClasses.yesNotSelected;
                tmtOptionEls[i].children[1].className = this.tmtOptionSelectionClasses.noSelected;
            }

        }

    };

    render() {
        return (
            <React.Fragment>
                <div className="optionsBox row" style={{height: '2em', marginLeft: '-10em'}}>
                    <div className="col-3 offset-8">
                        <img className="infoButton" id="infoButtonCheck" src={SVGOptions} alt="options-svg"
                             onClick={this.onOptionsClick}/>
                        <img className="infoButton" id="infoButtonX" src={SVGX} alt="options-svg"
                             onClick={this.onOptionsClick}/>
                        <span style={{marginLeft: '2em'}}>
                            <TooltipCreateWizardButton/>
                        </span>
                    </div>
                </div>
                <div className="optionsBox row" style={{marginLeft: '-10em'}}>
                    <div className="col-3 offset-8" id="padSampleNotification">
                        <PadSampleNotification/>
                    </div>
                </div>
                <div>
                    <div className="row justify-content-center">
                        <div className="col-3">
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm"># Samples</span>
                                </div>
                                <input type="text" className="form-control userInput1" id="test22" name={this.htmlTagNames.nSamples} onKeyUp={this.onKeyUp}/>
                            </div>
                            { this.state.nSamplesError &&
                                <span className="errorMessage">{this.nErrorMessage}</span>
                            }
                        </div>
                    </div>
                    <div className="row justify-content-center vRowSpacer">
                        <div className="col-3">
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroup-sizing-sm"># Conditions</span>
                                </div>
                                <input type="text" className="form-control userInput1" name={this.htmlTagNames.nConditions} onKeyUp={this.onKeyUp}/>
                            </div>
                            { this.state.nConditionsError &&
                                <span className="errorMessage">{this.nErrorMessage}</span>
                            }
                        </div>
                    </div>
                    <div className="row justify-content-center vRowSpacer">
                        <div className="col-3">
                            <div className="input-group input-group-sm">
                                <div className="input-group-prepend">
                                    <label className="input-group-text" htmlFor="inputGroupSelect01">Channels</label>
                                </div>
                                <select className="custom-select userInput1" name={this.htmlTagNames.channels} onChange={this.onChange}>
                                    <option>Choose...</option>
                                    <option value="6">6</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="16">16</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <p/>
                    { !this.state.askSampleReplicates &&
                        <div className="row justify-content-center">
                            <div className="col-2 submitButton" onClick={this.onApplySampleSummary}>
                                <span>Apply</span>
                            </div>
                        </div>
                    }
                    { this.state.askSampleReplicates &&
                        <div className="row justify-content-center">
                            <div className="col-2 resetButton" onClick={this.onReset}>
                                <span>Reset</span>
                            </div>
                        </div>
                    }
                    { this.state.onApplyErrorMsg.length > 0 &&
                        <div className="row justify-content-center">
                            <div className="col-2 errorMessage">
                                Missing values:
                                <ul>
                                    { this.state.onApplyErrorMsg.map((obj, i) => {
                                            return <li key={i}>{obj}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    }
                    <p/>
                    { this.state.askSampleReplicates &&
                        <div className="row justify-content-center">
                            <div className="col-3" style={{textAlign: 'center'}}>Enter the number of replicates for each biological sample:</div>
                        </div>
                    }
                    { this.state.askSampleReplicates &&
                        this.createReplicates()
                    }
                    <p/>
                    { this.state.askSampleReplicates && !this.state.createGrid &&
                    <div className="row justify-content-center">
                        <div className="col-2 submitButton" onClick={this.onApplySampleReplicates}>
                            <span>Apply</span>
                        </div>
                    </div>
                    }
                    { this.state.askSampleReplicates && this.state.createGrid &&
                    <React.Fragment>
                    <div className="row justify-content-center">
                        <div className="col-2 resetButton" onClick={this.onReset}>
                            <span>Reset</span>
                        </div>
                    </div>
                    <p/>
                    <div className="row justify-content-center">
                        <Link className="col-3 createExperimentButton simpleLink" to="/experiment">Design TMT Experiment Layout</Link>
                    </div>
                    </React.Fragment>
                    }
                    { this.state.createGrid &&
                        <GridSampleSummary
                            nSamples={this.state.nSamples}
                            nConditions={this.state.nConditions}
                            channel={this.state.channel}
                            sampleReplicates={this.state.sampleReplicates}
                        />
                    }
                    <div className="row" id="tmtSelectionOptionsGroup">
                        <div className="tmtOptionsPopup col-6 offset-2">
                            <div className="row">
                                <div className="tmtOptionBinary col-2 offset-3" id={this.tmtOptionSelections.calibrationChannelSelection.name}><span className={this.tmtOptionSelectionClasses.yesSelected} onClick={this.onOptionSelect}>YES</span> | <span className={this.tmtOptionSelectionClasses.noNotSelected} onClick={this.onOptionSelect}>NO</span></div>
                                <div className="tmtOptionsText col-6">Calibration Channel</div>
                            </div>
                            <div className="row">
                                <div className="tmtOptionBinary col-2 offset-3" id={this.tmtOptionSelections.balanceConditionsSelection.name}><span className={this.tmtOptionSelectionClasses.yesSelected} onClick={this.onOptionSelect}>YES</span> | <span className={this.tmtOptionSelectionClasses.noNotSelected} onClick={this.onOptionSelect}>NO</span></div>
                                <div className="tmtOptionsText col-6">Balance Conditions across Plexes</div>
                            </div>
                            <div className="row">
                                <div className="tmtOptionBinary col-2 offset-3" id={this.tmtOptionSelections.balanceReplicatesSelection.name}><span className={this.tmtOptionSelectionClasses.yesSelected} onClick={this.onOptionSelect}>YES</span> | <span className={this.tmtOptionSelectionClasses.noNotSelected} onClick={this.onOptionSelect}>NO</span></div>
                                <div className="tmtOptionsText col-6">Balance Replicates across Plexes</div>
                            </div>
                            <div className="row">
                                <div className="tmtOptionBinary col-2 offset-3" id={this.tmtOptionSelections.triggerChannelSelection.name}><span className={this.tmtOptionSelectionClasses.yesNotSelected} onClick={this.onOptionSelect}>YES</span> | <span className={this.tmtOptionSelectionClasses.noSelected} onClick={this.onOptionSelect}>NO</span></div>
                                <div className="tmtOptionsText col-6">Trigger Channel</div>
                            </div>
                            <div className="row">
                                <div className="tmtOptionBinary col-2 offset-3" id={this.tmtOptionSelections.randomizeSamplesSelection.name}><span className={this.tmtOptionSelectionClasses.yesSelected} onClick={this.onOptionSelect}>YES</span> | <span className={this.tmtOptionSelectionClasses.noNotSelected} onClick={this.onOptionSelect}>NO</span></div>
                                <div className="tmtOptionsText col-6">Randomize Samples</div>
                            </div>
                            <div className="row">
                                <div className="tmtOptionBinary col-2 offset-3" id={this.tmtOptionSelections.variabilityAssessmentChanelSelection.name}><span className={this.tmtOptionSelectionClasses.yesSelected} onClick={this.onOptionSelect}>YES</span> | <span className={this.tmtOptionSelectionClasses.noNotSelected} onClick={this.onOptionSelect}>NO</span></div>
                                <div className="tmtOptionsText col-6">Include Variability Assessment Channel</div>
                            </div>
                            <div className="row">
                                <div className="tmtOptionBinary col-2 offset-3" id={this.tmtOptionSelections.autopadSelection.name}><span className={this.tmtOptionSelectionClasses.yesSelected} onClick={this.onOptionSelect}>YES</span> | <span className={this.tmtOptionSelectionClasses.noNotSelected} onClick={this.onOptionSelect}>NO</span></div>
                                <div className="tmtOptionsText col-6">Enable Sample Auto Padding</div>
                            </div>
                        </div>
                    </div>
                    {this.state.showTooltip &&
                        <TooltipCreateWizardPopup/>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default CreateWizard;