import React from 'react';
import { useEffect, useState } from 'react';
import StateService from '../services/StateService';
import TmtConstraintService from '../services/TmtConstraintService';

export default (props) => {

    const [ nVacancies, setNVacancies ] = useState(0);

    useEffect(() => {

        let tmtOptions = null;
        let sampleReplicates = null;
        let nChannels = null;

        let subscriptions = {
            tmtOptions: null,
            sampleReplicates: null,
            nChannels: null,
            resetCreateWizard: null
        };

        const constraintService = new TmtConstraintService();

        const getRequiredPadCount = (tmtOptions, sampleReplicates, nChannels) => {
            if (tmtOptions && sampleReplicates && nChannels) {

                let calibrationOrTrigger = false;

                const prelimFormattedData = [];
                sampleReplicates.forEach((replicate, index) => {
                    for (let i = 1; i <= replicate; i++) {
                        prelimFormattedData.push({sample: index + 1, replicateGroup: i, sampleName: '', conditions: [] });
                    }
                });

                if ((tmtOptions.calibrationChannelSelection || tmtOptions.triggerChannelSelection) && tmtOptions.variabilityAssessmentChanelSelection) {
                    if (tmtOptions.calibrationChannelSelection.selection || tmtOptions.triggerChannelSelection.selection) {
                        calibrationOrTrigger = true;
                    }

                    const vacancyData = constraintService.getVacantChannels(prelimFormattedData, nChannels, 'calibration', calibrationOrTrigger, tmtOptions.variabilityAssessmentChanelSelection.selection);
                    setNVacancies(vacancyData.nVacantChannels);
                }
            }
        };

        subscriptions.tmtOptions = StateService.tmtOptions$().subscribe(res => {
            tmtOptions = res;
            getRequiredPadCount(tmtOptions, sampleReplicates, nChannels);
        });

        subscriptions.sampleReplicates = StateService.sampleReplicates$().subscribe(res => {
            if (res) {
                sampleReplicates = res.sampleReplicates;
                getRequiredPadCount(tmtOptions, sampleReplicates, nChannels);
            }
        });

        subscriptions.nChannels = StateService.nChannels$().subscribe(res => {
            if (res) {
                nChannels = res.nChannels;
            }
        });

        subscriptions.resetCreateWizard = StateService.resetCreateWizard$().subscribe(() => {
            setNVacancies(0);
        });

        return () => {
            subscriptions.sampleReplicates.unsubscribe();
            subscriptions.resetCreateWizard.unsubscribe();
            subscriptions.tmtOptions.unsubscribe();
            subscriptions.nChannels.unsubscribe();
        };
    }, []);

    return (
        <div className="col-2 notificationBox">
            <div className="row justify-content-center">
                <div className="col-12" style={{textAlign: 'center'}}>
                    Pad Notification
                </div>
                <div className="col-12" style={{textAlign: 'center'}}>
                    Auto Padding: <span style={{color: '#00ffb2'}}>{nVacancies}</span> samples
                </div>
            </div>
        </div>
    )
}