import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Navbar extends Component {

    constructor () {
        super();

        this.state = {
            selectedLink: 'home'
        };

    }

    onSelect = e => {
        document.getElementById(e.target.id).style.className = 'nav-item nav-link navbarLink navbarSelected';
    };

    render() {
        return (
            <React.Fragment>
                <nav className="navbar navbar-expand-lg navbar-light bg-light customNavbar">
                    <span className="navbar-brand">TMT</span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Link className="navbarLink" to="/">Create</Link>
                            <Link className="navbarLink" to="/upload">Upload</Link>
                            {/*<Link className="navbarLink" to="/create/experiment/wizard">Experiment</Link>*/}
                        </div>
                    </div>
                </nav>
            </React.Fragment>
        );
    }
}

export default Navbar;