import {BehaviorSubject} from 'rxjs';

class StateService {

    constructor() {

        this.nSamples = 0;
        this.sampleReplicates = new BehaviorSubject();
        this.nChannels = new BehaviorSubject();
        this.tmtGridData = new BehaviorSubject();
        this.tmtOptions = new BehaviorSubject();
        this.resetCreateWizard = new BehaviorSubject();
        this.tooltipCreateWizardPopup = new BehaviorSubject();

    }

    sampleReplicates$ = () => {
        this.sampleReplicates.subscribe(res => {
            if (res) {
                this.nSamples = res.sampleReplicates.length;
            }
        });

        return this.sampleReplicates;
    };

    nChannels$ = () => {
        return this.nChannels;
    };

    tmtGridData$ = () => {
        return this.tmtGridData;
    };

    tmtOptions$ = () => {
        return this.tmtOptions;
    };

    resetCreateWizard$ = () => {
        return this.resetCreateWizard;
    };

    showCreateWizardPopup$ = () => {
        return this.tooltipCreateWizardPopup;
    };

}

export default new StateService();