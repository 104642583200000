export default class CsvExportService {

    json2csv = json => {

        const rows = [];
        const headers = [];
        let csvString = '';

        for (const header of Object.keys(json[0])) {
            if (header === 'labels') {
                json[0][header].forEach(e => {
                    headers.push(e);
                });
            } else if (header === 'sampleAssignments') {

            } else {
                headers.push(header);
            }
        }

        for (const plex of json) {
            const row = [];

            row.push(plex.plex);
            row.push(plex.experimentId);
            row.push(plex.design);
            row.push(plex.channels);

            plex.sampleAssignments.forEach(e => {
                row.push(e);
            });

            rows.push(row);

        }

        for (let i = 0; i < headers.length; i++) {
            if (i === headers.length - 1) {
                csvString += headers[i] + '\n';
            } else {
                csvString += headers[i] + ',';
            }
        }

        for (const row of rows) {
            for (let i = 0; i < row.length; i++) {
                if (i === row.length - 1) {
                    csvString += row[i] + '\n';
                } else {
                    csvString += row[i] + ','
                }
            }
        }

        return csvString;

    }

}